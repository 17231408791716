export const staticData = {
    navList: {
        about: 'About',
        features: 'Features',
        services: 'Services',
        contact: 'Contact'
    },
    bannerList: {
        desc: 'We provide liquidity to businesses and high-net-worth individuals interested in large transactions.',
        ourService: 'Our Services'
    },
    aboutList: {
        desc1: 'SaintPay Fintech LLC is a Virtual Asset Service Provider, whose primary activity is a Crypto/Fiat On & Off-ramp (no securities) dedicated mainly to businesses, large institutions, and high net worth individuals (HNI) but will also serve retail customers through its institutional partnerships and collaborations.',
        desc2: 'Additionally, SaintPay Fintech LLC offers over-the-counter (OTC) transactions and block trading.'
    },
    featureList: [
        {
            icon: 'cs',
            title: 'One-to-one service',
            desc: 'From initial expert consultation to execution of trades, including trade reports and market analysis.'
        },
        {
            icon: 'kyc',
            title: 'Secure Verification',
            desc: 'Enjoy state-of-the-art Know Your Customer (KYC) security procedures.'
        },
        {
            icon: 'transfer',
            title: 'Transfer Completion',
            desc: 'Your transactions are secure with us through a multi-channel payment platform.'
        },
        {
            icon: 'security',
            title: 'Safety',
            desc: 'Front-end scripts scan for the presence of unauthorised activities at all times.'
        },
        {
            icon: 'inslight',
            title: 'Professional Market Insight',
            desc: 'As an OTC client, you will receive daily trade and traditional and crypto market insights.'
        },
        {
            icon: 'fast',
            title: 'Speed',
            desc: 'Alternative methods that reach everyone. Have an accessible product or service and grow your business.'
        }
    ],
    servicesList: [
        {
            title: 'Crypto On/Off-ramp',
            content1:
                'SaintPay aims to offer conversion to and from all local Fiat currencies through global and local providers. Crypto On-ramp is a system that allows for the purchase of crypto assets by using fiat currencies.',
            content2:
                'Conversely, the crypto Off-ramp is the mechanism that allows users to exchange the crypto assets for their preferred fiat currency. SaintPay builds relationships with industry leaders and helps set the standards for this dynamic, fast-paced market as well as seeks to provide the industry with best-in-class quality, security, and reliability.'
        },
        {
            title: 'Peer-to-peer corporate & institutional transactions',
            content1:
                'SaintPay offers corporate and institutional clients to facilitate transactions, we support projects and other web3 products that are in need of payment networks and a company to support their fiat-crypto transactions.'
        },
        {
            title: 'Payment facilitation, payment traffic services (electronic transfers, credit cards)',
            content1:
                'SaintPay will enable payment facilitation for institutions through electronic transfers, and credit cards across the world.'
        }
    ],
    contactsList: {
        address:
            'First floor, First St Vincent Bank Ltd Building, James street, Kingstown, St. Vincent and the Grenadines.',
        mail: 'contact@saintpayment.com',
        legalAddress: 'legal@saintpayment.com'
    },
    footerList: {
        terms: [
            {
                term: 'Terms and Conditions',
                link: 'tc'
            },
            {
                term: 'Privacy Policy',
                link: 'Privacy'
            }
        ],
        contact: 'Contact',
        copyRight: '© 2023 All rights reserved.'
    }
}
